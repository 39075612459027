.App {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
  margin: 20px;
}

.model {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: dodgerblue;
}

.qa-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 2rem;
  margin: 2em 2em 1em 0em;
}

.qa {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.gpt-answer {
  width: 100%;
  text-align: left;
  padding: 1em 2em 2em 2em;
  border-radius: 10px;
  background-color: rgb(240, 240, 255);
}

.question {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5em 1.5em 1.5em 1.5em;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.question > form {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.ta-answer {
  padding: 0.5em 1.5em 1.5em 1.5em;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.multiline-p {
  text-align: left;
  white-space: pre-line;
  flex: 1 0 auto;
}

.eval-form {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  flex-direction: row;
}

.label-btn {
  width: 150px;
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  justify-content: flex-start;
  border: none;
  border-radius: 10px;
}

.user-input {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.user-input.white > textarea {
  background-color: white;
}

.user-input > textarea {
  flex: 1 0 auto;
  font-size: 15px;
  color: dimgray;
  padding: 1.5em;
  border: none;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.submit-btn {
  display: block;
  border: none;
  font-size: medium;
  margin-top: 1em;
  line-height: 2em;
  border-radius: 5px;
  background-color: #d6d6d6;
}

.submit-btn:hover {
  cursor: pointer;
  background-color: #9e9e9e;
  color: white;
}

.button-19.green {
  background-color: #459847;
}

.button-19.green:after {
  background-color: #5bc15b;
}

/* Duolingo Button CSS https://getcssscan.com/css-buttons-examples */
.button-19 {
  appearance: button;
  background-color: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  margin-right: 1em;
  outline: none;
  overflow: visible;
  padding: 13px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  vertical-align: middle;
  white-space: nowrap;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #1CB0F6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}